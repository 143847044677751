var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "person_infor"
  }, [_c('div', {
    staticClass: "login_top"
  }, [_c('div', {
    staticClass: "login_title"
  }, [_vm._v(_vm._s(_vm.$t('set.emailAuthentication')))])])]), _c('div', {
    staticClass: "now_email"
  }, [_vm._m(0), _c('div', [_c('span', {
    staticClass: "email"
  }, [_vm._v(_vm._s(_vm.$t('curEmailNum')) + "：")]), _c('span', {
    staticClass: "email_num"
  }, [_vm._v(_vm._s(_vm.user_email))])])]), _c('div', {
    staticClass: "input_item"
  }, [_vm._m(1), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.code,
      expression: "code"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('login.inputCode')
    },
    domProps: {
      "value": _vm.code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.code = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "code"
  }, [!_vm.isGetCode ? _c('div', {
    staticClass: "code1",
    on: {
      "click": _vm.getCode
    }
  }, [_vm._v(_vm._s(_vm.$t('login.getCode')))]) : _c('div', {
    staticClass: "code2"
  }, [_vm._v(_vm._s(_vm.time) + _vm._s(_vm.$t('login.sendAgain')))])])]), _c('div', {
    staticClass: "input_item"
  }, [_vm._m(2), _c('div', {
    staticClass: "input"
  }, [(_vm.isShowPassword1 ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    attrs: {
      "placeholder": _vm.$t('set.inputNewPassword'),
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.password) ? _vm._i(_vm.password, null) > -1 : _vm.password
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.password = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.password = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.password = $$c;
        }
      }
    }
  }) : (_vm.isShowPassword1 ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    attrs: {
      "placeholder": _vm.$t('set.inputNewPassword'),
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.password, null)
    },
    on: {
      "change": function ($event) {
        _vm.password = null;
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    attrs: {
      "placeholder": _vm.$t('set.inputNewPassword'),
      "type": _vm.isShowPassword1 ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.password = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "show_icon",
    on: {
      "click": function ($event) {
        _vm.isShowPassword1 = !_vm.isShowPassword1;
      }
    }
  }, [_vm.isShowPassword1 ? _c('img', {
    attrs: {
      "src": require("../../../assets/login/show.png")
    }
  }) : _c('img', {
    attrs: {
      "src": require("../../../assets/login/hide.png")
    }
  })])]), _c('div', {
    staticClass: "input_item"
  }, [_vm._m(3), _c('div', {
    staticClass: "input"
  }, [(_vm.isShowPassword2 ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password2,
      expression: "password2"
    }],
    attrs: {
      "placeholder": _vm.$t('set.inputConfirmPasswordAgain'),
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.password2) ? _vm._i(_vm.password2, null) > -1 : _vm.password2
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.password2,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.password2 = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.password2 = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.password2 = $$c;
        }
      }
    }
  }) : (_vm.isShowPassword2 ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password2,
      expression: "password2"
    }],
    attrs: {
      "placeholder": _vm.$t('set.inputConfirmPasswordAgain'),
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.password2, null)
    },
    on: {
      "change": function ($event) {
        _vm.password2 = null;
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password2,
      expression: "password2"
    }],
    attrs: {
      "placeholder": _vm.$t('set.inputConfirmPasswordAgain'),
      "type": _vm.isShowPassword2 ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.password2
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.password2 = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "show_icon",
    on: {
      "click": function ($event) {
        _vm.isShowPassword2 = !_vm.isShowPassword2;
      }
    }
  }, [_vm.isShowPassword2 ? _c('img', {
    attrs: {
      "src": require("../../../assets/login/show.png")
    }
  }) : _c('img', {
    attrs: {
      "src": require("../../../assets/login/hide.png")
    }
  })])]), _c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.verifyCode();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('confirm')) + " ")])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input_item_icon"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/mycenter/email-1.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input_item_icon"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/mycenter/code.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input_item_icon"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/mycenter/password.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input_item_icon"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/mycenter/password.png")
    }
  })]);
}];
export { render, staticRenderFns };