import { validate } from '@/common/function/validate.js';
export default {
  data() {
    return {
      user_email: '',
      code: '',
      mobile_code: '',
      password: '',
      password2: '',
      time: 60,
      isGetCode: false,
      //是否获取验证码
      timer: '',
      //定时器

      isShowPassword1: false,
      isShowPassword2: false //是否显示验证码
    };
  },

  mounted() {
    let userinfo = this.$store.state.userinfo;
    this.user_email = userinfo.user_email;
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    // 获取验证码
    getCode() {
      let data = {
        type: 7,
        is_test: 0,
        user_email: this.user_email
      };
      this.$http.getCode(data).then(res => {
        if (res.code == 1) {
          this.$message.success(this.$t('sendSuccess'));
          this.isGetCode = true;
          this.timer = setInterval(() => {
            if (this.time == 1) {
              clearInterval(this.timer);
              this.time = 60;
              this.isGetCode = false;
            }
            this.time--;
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      }).catch(v => {});
    },
    /* 校验验证码 */
    verifyCode() {
      if (!this.code) {
        this.$message.error(this.$t('tips.codeCannotBeEmpty'));
        return;
      }

      //  2022-09-15号修改
      this.$http.verifyCode({
        user_email: this.user_email,
        code: this.code,
        type: 7
      }).then(res => {
        if (res.code == 1) {
          this.modifyPassword();
        } else {
          this.$message.error(res.msg);
        }
      });
      this.modifyPassword();
    },
    // 修改密码
    modifyPassword() {
      if (!this.password) {
        this.$message.error(this.$t('tips.newPasswordCannotEmpty'));
        return;
      }
      if (!this.password2) {
        this.$message.error(this.$t('tips.confirmPasswordCannotEmpty'));
        return;
      }
      if (this.password != this.password2) {
        this.$message.error(this.$t('tips.theTwoPasswordsAreInconsistent'));
        return;
      }
      let data = {
        password: this.password,
        password_code: this.password2
      };
      this.$http.editPassword(data).then(res => {
        this.$message.success(res.msg);
        if (res.code == 1) {
          setTimeout(() => {
            this.clearInput();
          }, 2000);
        }
      });
    },
    // 清空输入框
    clearInput() {
      this.code = '';
      this.password = '';
      this.password2 = '';
      this.isGetCode = false;
      clearInterval(this.timer);
      this.time = 60;
    }
  }
};